@import "../../styles/main.scss";
@import "../../styles/variables.scss";

.heading {
  @extend .heading;
}

.container {
  display: flex;
  flex-direction: row;
  margin: 0 20% 10%;
  width: 60%;

  // @media (min-width: 48rem) and (max-width: 64rem) {
  //   margin: 0 %;
  //   width: 80%;
  // }

  @media (min-width: 20rem) and (max-width: 48rem) {
    flex-direction: column-reverse;
    margin: 0 3%;
    width: 94%;
  }
}

.picture {
  width: 84%;
  height: auto;
  padding: 15px 8%;
}

.picDiv {
  flex: 1;
}

.skillText {
  margin: 25px 0 0 0;
}

.content {
  flex: 1;
}

.about {
  margin: 10px 0 0 40px;

  @media (min-width: 20rem) and (max-width: 48rem) {
    margin: 30px 0;
  }

  p {
    text-align: justify;
  }
}

.subHeading {
  @extend .subHeading;

  @media (min-width: 20rem) and (max-width: 48rem) {
    text-align: center;
  }
}

.skillSubHeading {
  @extend .subHeading;

  margin-top: 50px;
}

.text {
  @extend .text;

  line-height: 24px;
  margin-top: 25px;
  // text-align: justify;
}

.aboutMe {
  white-space: pre-line;
}

.connectBtn {
  color: #2aa8cc;
  cursor: pointer;
}
