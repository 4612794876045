.container {
  display: none;

  @media (min-width: 20rem) and (max-width: 48rem) {
    display: inline;
  }
}

.sidepanel {
  position: fixed;
  z-index: 1;
  height: 100vh;
  width: 80%;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.navItems {
  margin: 50px;
}

.item {
  cursor: pointer;
  // padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 56px;
  color: #2a2a2a;
  display: block;
  transition: 0.3s;
}

.selectedItem {
  color: #2aa8cc;
}

// .item:hover {
//   color: #f1f1f1;
// }

.closebtn {
  cursor: pointer;
  color: #2a2a2a;
  font-size: 38px;
  margin: 0;
  margin-left: 82%;
}

.openbtn {
  margin: auto;

  img {
    width: 22px;
    cursor: pointer;
  }
}
