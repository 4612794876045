@import "../../styles/main.scss";
@import "../../styles/variables.scss";

.heading {
  @extend .heading;
}

.container {
  display: flex;
  flex-direction: row;
  margin: 0 20%;
  width: 60%;

  @media (min-width: 20rem) and (max-width: 48rem) {
    flex-direction: column;
    margin: 0 3%;
    width: 94%;
  }
}

.picture {
  width: 100%;
  height: auto;
}

.contactDiv {
  margin: 50px 0 0 100px;
  flex: 1;

  .text {
    @extend .text;
    line-height: 24px;
  }

  @media (min-width: 20rem) and (max-width: 48rem) {
    margin: 0;
  }
}

.formDiv {
  flex: 1;
}

.about {
  margin: 10px 0 0 40px;

  @media (min-width: 20rem) and (max-width: 48rem) {
    margin: 30px 0;
  }
}

.subHeading {
  @extend .subHeading;

  @media (min-width: 20rem) and (max-width: 48rem) {
    text-align: center;
  }
}

.text {
  @extend .text;

  line-height: 24px;
  margin-top: 50px;
  text-align: justify;
}

.connectBtn {
  color: #2aa8cc;
  cursor: pointer;
}

.socialLogo {
  margin: 10px 30px 0 0;
  width: 45px;
}
