@import "../../styles/main.scss";
@import "../../styles/variables.scss";

.heading {
  @extend .heading;
}

.container {
  margin: 0 20%;
  width: 60%;

  @media (min-width: 20rem) and (max-width: 48rem) {
    margin: 0 3%;
    width: 94%;
  }
}

.subHeading {
  @extend .subHeading;

  width: 100%;
  text-align: center;
  margin-top: -25px;

  @media (min-width: 20rem) and (max-width: 48rem) {
    text-align: center;
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;

  .project {
    flex: 0 0 30%;
    margin-top: 40px;

    @media (min-width: 20rem) and (max-width: 48rem) {
      flex: 1 0 60%;
      margin: 0;
    }

    &:nth-child(2),
    &:nth-child(5) {
      @media (min-width: 48rem) {
        margin-left: 5%;
        margin-right: 5%;
      }
    }

    // &:hover {
    //   opacity: 0.9;
    // }

    .projectImage {
      // background: #eae6e6;
      width: 100%;
      // height: 250px;

      @media (min-width: 20rem) and (max-width: 48rem) {
        margin-top: 20px;
      }
    }

    .projectHeading {
      @extend .text;

      line-height: 24px;
      font-weight: 500;
    }

    .text {
      @extend .text;

      line-height: 24px;
      font-weight: 300;
      height: 72px; // Fixed height for description

      @media (min-width: 20rem) and (max-width: 48rem) {
        height: auto;
      }
    }

    .seeMoreDiv {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .seeMoreBtn {
        color: #2aa8cc;
        cursor: pointer;
        text-decoration: none;

        p {
          margin: 0;
        }
      }

      .githubLink {
        text-align: right;
        width: 40px;
      }
    }
  }
}
